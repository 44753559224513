<template>
    <div>
        <v-row align="center" class="mb-3">
            <v-spacer />
            <v-col cols="12" md="3">
                <v-text-field
                    v-debounce:500ms="getItems"
                    v-model="$global.state.filter.search"
                    label="ابحث هنا"
                    color="indigo"
                    hide-details
                    prepend-inner-icon="search"
                    outlined
                    dense
                    filled
                />
            </v-col>

            <v-col cols="12" md="3">
                <v-autocomplete
                    v-model="$global.state.complaintType"
                    @input="getItems()"
                    :items="complaintsTypes"
                    :loading="$global.state.loading"
                    clearable
                    item-text="name"
                    item-value="value"
                    color="indigo"
                    dense
                    label="النوع"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    prepend-inner-icon="fi fi-br-exclamation"
                    outlined
                    filled
                />
            </v-col>
        </v-row>

        <v-card id="card">
            <v-data-table
                class="mytable"
                :items="$global.state.complaints"
                :loading="$global.state.loading"
                :headers="headers"
                :search="$global.state.filter.search"
                hide-default-footer
                loading-text="جاري تحميل البيانات"
                no-data-text="لا توجد بيانات"
                :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
                :items-per-page="$global.state.filter.pageSize"
                @click:row="getDetails"
                style="cursor: pointer;">
                <template v-slot:item.created="{ item }">
                    <span>{{new Date(item.created).toISOString().substring(0,10)}}</span>
                </template>

                <template v-slot:item.type="{ item }">
                    <span v-if="item.type == 1" class="error--text">شكوى</span>
                    <span v-if="item.type == 2" class="green--text">اقتراح</span>
                </template>

                <template v-slot:item.created="{ item }">
                    <div>{{new Date(item.created).toISOString().substring(0,10)}}</div>
                </template>

                <template v-slot:item.actions="{item}">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="openEditDialog(item)" v-on="on" icon x-small>
                                <v-icon color="green">fa-solid fa-reply</v-icon>
                            </v-btn>
                        </template>
                        <span>رد</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>

        <div class="d-flex justify-space-between py-3">
            <v-spacer></v-spacer>
            <Pagination />
        </div>
        <Dialog />
    </div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {
            headers: [
                {
                    text: "الزبون",
                    value: "formUserFullName",
                },
                {
                    text: "تاريخ الشكوى",
                    value: "created",
                },

                {
                    text: "نص الشكوى",
                    value: "body",
                },
                {
                    text: "الرد",
                    value: "replay",
                },
                {
                    text: "النوع",
                    value: "type",
                },
                {
                    text: "الاجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],

            complaintsTypes: [
                {
                    name: "الكل",
                    value: null,
                },
                {
                    name: "الشكاوي",
                    value: 1,
                },
                {
                    name: "المقترحات",
                    value: 2,
                },
            ],
        };
    },

    mounted() {
        this.getItems();
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(
                `get${this.$route.meta.endPoint}`,
                this.$route.meta.role
            );
        },

        getDetails(item) {
            this.$router.push({
                name: "complaintsDetails",
                params: {
                    obj: item,
                },
            });
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>

<style scoped>

</style>
